import React, { useState, useEffect } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { BiInfoCircle } from "react-icons/bi";
import { HiOutlineInbox } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { adventureRoutes } from "../../../../adventures_module/routes/routes";
import { ccfRoutes } from "../../../../ccfWebsite_module/routes/routes";
import { medEvalRoutes } from "../../../../medEvaluation_module/routes/routes";
import { profferRoutes } from "../../../../proffer_module/routes/routes";
import { STORAGE_KEYS, USER_ROLES } from "../../../enums/sharedEnums";
import { sharedRoutes } from "../../../routes/routes";
import { signOut } from "../../../services/auth.service";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { forayRoutes } from "../../../../foray_module/routes/routes";

const Sidebar = () => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const [isExpanded, setIsExpanded] = useState(true);
  const [width, setWidth] = useState("90px");
  const [isAbsolute, setIsAbsolute] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1200);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth <= 1200;
      setIsSmallScreen(smallScreen);

      if (!smallScreen) {
        setIsAbsolute(false);
        setWidth(isExpanded ? "290px" : "90px");
      } else {
        setIsAbsolute(isExpanded);
        setWidth(isExpanded ? "290px" : "90px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isExpanded]);

  const toggleSidebar = () => {
    if (isSmallScreen) {
      setIsAbsolute(!isExpanded);
      setWidth(isExpanded ? "90px" : "100%");
    } else {
      setIsExpanded(!isExpanded);
      setWidth(isExpanded ? "90px" : "290px");
    }
    setIsExpanded(!isExpanded);
  };

  const handleClick = (dashboard: string) => {
    if (dashboard === "pr") {
      navigate(profferRoutes.dashboard);
    } else if (dashboard === "md") {
      navigate(medEvalRoutes.dashboard);
    } else if (dashboard === "fr") {
      navigate(forayRoutes.center_view)
    }
  };

  const handleNavToInBuild = (title: string) => {
    navigate(sharedRoutes.coming_soon, { state: { title } });
  };

  const handleSignOut = () => {
    signOut();
    handleNav();
  };

  const handleNav = () => {
    const pathCheck = window.location.pathname.split("/")[1];
    if (pathCheck.includes("pr")) navigate(profferRoutes.home);
    else if (pathCheck.includes("adv")) navigate(adventureRoutes.home);
    else if (pathCheck.includes("ccf")) navigate(ccfRoutes.home);
    else if (pathCheck.includes("md")) navigate(medEvalRoutes.eval_center_home);
    else navigate(ccfRoutes.home);
  };

  return (
    <div
      className={`bg-white h-screen transition-all duration-300 ${isAbsolute ? "fixed top-0 left-0 z-[9999] shadow-lg" : "relative"
        }`}
      style={{ width }}
    >
      <div className="relative px-4 pt-6 pb-8">
        <div className="flex items-center justify-between">
          <img
            src={
              isExpanded
                ? "../../images/web-logo.png"
                : "../../images/collapse-web-logo.png"
            }
            alt="web logo"
            className="w-auto"
          />
          <button
            onClick={toggleSidebar}
            className={`rounded-full bg-primary p-1 text-white absolute -right-3 top-7 transition-transform `}
          >
            {isExpanded ? (
              <FaChevronLeft className="w-3.5 h-3.5" />
            ) : (
              <FaChevronRight className="w-3.5 h-3.5" />
            )}
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-between h-[calc(100vh-130px)]">
        <div>
          <ul className="flex items-start flex-col gap-3 px-4">
            <li className="w-full">
              <Link
                to="#"
                className="text-lg text-secondary font-medium flex items-center gap-3"
              >
                <MdOutlineDashboardCustomize className="w-6 h-6" />
                {isExpanded && "Dashboard"}
              </Link>
            </li>
            <li className="w-full">
              <details
                className="group [&_summary::-webkit-details-marker]:hidden"
                open
              >
                <summary className="flex cursor-pointer items-center justify-between gap-3 text-gray-900">
                  <span className="text-lg text-secondary font-medium flex items-center gap-3">
                    <HiOutlineInbox className="w-6 h-6" />
                    {isExpanded && "Subscribed Services"}
                  </span>
                  {isExpanded && (
                    <svg
                      className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  )}
                </summary>
                {isExpanded && (
                  <div className="mt-4 leading-relaxed">
                    <ul className="space-y-3">
                      <li
                        className={`text-base hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full ${location.pathname.includes("pr")
                          ? "text-primary bg-primaryVariant2 font-medium"
                          : "text-secondaryVariant"
                          }`}
                      >
                        <button
                          className="w-full text-start"
                          onClick={() => handleClick("pr")}
                        >
                          PrOffer
                        </button>
                      </li>
                      {role !== USER_ROLES.PARENT && (
                        <li
                          className={`text-base hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full ${location.pathname.includes("md")
                            ? "text-primary bg-primaryVariant2 font-medium"
                            : "text-secondaryVariant"
                            }`}
                        >
                          <button
                            className="w-full text-start"
                            onClick={() => handleClick("md")}
                          >
                            Medical Evaluations
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </details>
            </li>
            <li className="w-full">
              <details className="group [&_summary::-webkit-details-marker]:hidden">
                <summary className="flex cursor-pointer items-center justify-between gap-3 text-gray-900">
                  <span className="text-lg text-secondary font-medium flex items-center gap-3">
                    <HiOutlineInbox className="w-6 h-6" />
                    {isExpanded && "Center Services"}
                  </span>
                  {isExpanded && (
                    <svg
                      className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  )}
                </summary>
                {isExpanded && (
                  <div className="mt-4 leading-relaxed">
                    <ul className="space-y-3">
                      {role === USER_ROLES.TEACHER && (
                        <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                          <button
                            onClick={() =>
                              handleNavToInBuild("Child Care Employment")
                            }
                            className="w-full text-start"
                          >
                            Child Care Employment
                          </button>
                        </li>
                      )}
                      <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                        <button
                          onClick={() => handleNavToInBuild("LMS")}
                          className="w-full text-start"
                        >
                          LMS
                        </button>
                      </li>
                      <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                        <button
                          onClick={() => handleNavToInBuild("Foray")}
                          className="w-full text-start"
                        >
                          Foray
                        </button>
                      </li>
                      <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                        <button
                          onClick={() => handleNavToInBuild("JCC Operations")}
                          className="w-full text-start"
                        >
                          JCC Operations
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </details>
            </li>
            <li className="w-full">
              <details
                className="group [&_summary::-webkit-details-marker]:hidden"
                open
              >
                <summary className="flex cursor-pointer items-center justify-between gap-3 text-gray-900">
                  <span className="text-lg text-secondary font-medium flex items-center gap-3">
                    <HiOutlineInbox className="w-6 h-6" />
                    {isExpanded && "Foray"}
                  </span>
                  {isExpanded && (
                    <svg
                      className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  )}
                </summary>
                {isExpanded && (
                  <div className="mt-4 leading-relaxed">
                    <ul className="space-y-3">
                      {role === USER_ROLES.TEACHER && (
                        <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                          <button
                            onClick={() =>
                              handleNavToInBuild("Child Care Employment")
                            }
                            className="w-full text-start"
                          >
                            Foray
                          </button>
                        </li>
                      )}
                      <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                        <button
                          onClick={() => handleNavToInBuild("Foray")}
                          className="w-full text-start"
                        >
                          Dashboard
                        </button>
                      </li>
                      <li className={`text-base  hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full ${location.pathname.includes("/fr/setup/") ? "text-primary bg-primaryVariant2" : "text-secondaryVariant"}`}>
                        <button
                          onClick={() => handleClick("fr")}
                          className="w-full text-start"
                        >
                          Setup / Modify
                        </button>
                      </li>
                      <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                        <button
                          onClick={() => handleNavToInBuild("JCC Operations")}
                          className="w-full text-start"
                        >
                          Enrollment Tracking
                        </button>
                      </li>
                      <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                        <button
                          onClick={() => handleNavToInBuild("JCC Operations")}
                          className="w-full text-start"
                        >
                          Human Resouces
                        </button>
                      </li>
                      <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                        <button
                          onClick={() => handleNavToInBuild("JCC Operations")}
                          className="w-full text-start"
                        >
                          Checklist
                        </button>
                      </li>
                      <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                        <button
                          onClick={() => handleNavToInBuild("JCC Operations")}
                          className="w-full text-start"
                        >
                          Task Generation
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </details>
            </li>
          </ul>
        </div>
        <div>
          <ul className="flex flex-col items-start gap-3 px-4">
            <li className="py-3 w-full">
              <Link
                to={sharedRoutes.contact_us}
                className="text-lg text-secondary font-medium flex items-center gap-3"
              >
                <BiInfoCircle className="w-6 h-6" />
                {isExpanded && "Help Centre"}
              </Link>
            </li>
            <li className="py-3 w-full">
              <button
                className="text-lg text-dangerAlert font-medium flex items-center gap-3"
                onClick={handleSignOut}
              >
                <img src="../../images/logout-icon.svg" alt="logout" />
                {isExpanded && "Log out"}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
