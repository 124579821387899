import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import LoaderSpinner from "../shared/components/Loader/Loader";
import { forayRoutes } from "./routes/routes";
import ViewStudentProfile from "./pages/viewStudentProfile/ViewStudentProfile";

const CenterSetup = lazy(() => import("./pages/centerSetup/CenterSetup"));
const ClassroomSetup = lazy(() => import("./pages/classroomSetup/ClassroomSetup"));
const ClassroomList = lazy(() => import("./pages/classroomList/ClassroomList"));
const ForayCenterView = lazy(() => import("./pages/centerView/ForayCenterView"));
const ViewClassroom = lazy(() => import("./pages/viewClassroom/ViewClassroom"));
const StudentSetup = lazy(() => import("./pages/studentSetup/StudentSetup"));
const StudentList = lazy(() => import("./pages/studentList/StudentList"));

const ForayModuleRoutes: RouteObject[] = [
  {
    path: forayRoutes.center_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <CenterSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.classroom_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ClassroomSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.classroom_modify,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ClassroomSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.classroom_list,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ClassroomList />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.center_view,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ForayCenterView />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.view_classroom,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ViewClassroom />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.student_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <StudentSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.student_list,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <StudentList />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.view_student_profile,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ViewStudentProfile />
      </Suspense>
    ),
  },
];

export default ForayModuleRoutes;
