export const forayRoutes = {
  center_setup: "/fr/setup/center-setup",
  classroom_setup: "/fr/setup/classroom-setup",
  classroom_modify: "/fr/setup/classroom-modify",
  classroom_list: "/fr/classroom-list",
  center_view: "/fr/center-view",

  view_classroom: "/fr/classroom-view",
  student_setup: "/fr/setup/student-setup",
  student_list: "/fr/student-list",
  view_student_profile: "/fr/view-student-profile",
};
