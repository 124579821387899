import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import { LuHome } from "react-icons/lu";
import { GoChevronRight } from "react-icons/go";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { TbCamera } from "react-icons/tb";
import { RiUserLine } from "react-icons/ri";
import { AiOutlineTag } from "react-icons/ai";
import { IoMailOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";
import { CiGlobe } from "react-icons/ci";
import { FiPhoneCall } from "react-icons/fi";
import { PiUsers } from "react-icons/pi";
import { TbBus } from "react-icons/tb";
import { RxCountdownTimer } from "react-icons/rx";
import { LuCalendarDays } from "react-icons/lu";
import { PiCalendarDots } from "react-icons/pi";
import { LuCalendarX2 } from "react-icons/lu";
import { LiaCarSolid } from "react-icons/lia";
import { HiOutlineArrowRight } from "react-icons/hi";
import { PiSun } from "react-icons/pi";

const ViewStudentProfile = () => {
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 py-5 bg-secondaryNeutral overflow-y-scroll h-[calc(100vh-85px)] custom-scrollbar">
          <div className="mb-6 flex items-center gap-2">
            <LuHome className="w-5 h-5 text-secondaryVariant" />
            <GoChevronRight className="text-secondaryVariant2 w-[18px] h-[18px]" />
            <Link to="/" className="text-secondaryVariant2 text-sm font-medium">
              Student View All
            </Link>
            <GoChevronRight className="text-secondaryVariant2 w-[18px] h-[18px]" />
            <Link to="/" className="text-secondary text-sm font-semibold">
              Student Profile{" "}
            </Link>
          </div>
          <div className="flex items-center justify-between border-b border-secondaryNeutral pb-6 mb-4">
            <h2 className="text-2xl text-secondary font-semibold">
              Student profile
            </h2>
            <button className="text-statusColor text-lg flex items-center gap-1">
              Modify Student <BiEdit className="w-5 h-5" />
            </button>
          </div>
          <div className="bg-white p-6 rounded-xl mb-11">
            <div className="mb-8">
              <h2 className="text-xl text-secondary font-semibold mb-8">
                Student Information
              </h2>
              <span className="text-base text-secondaryVariant mb-3 block">
                Profile Picture (Optional){" "}
              </span>
              <img
                className="w-[175px] h-[175px] rounded-xl border-[0.5px] border-secondaryVariant2 mb-3"
                src="../../../images/Foray_images/dummyStudent-img.png"
                alt="student"
              />
              <label className="text-base text-secondary border border-secondaryVariant2 flex items-center gap-2 bg-white rounded-full py-[5px] px-4 cursor-pointer w-max">
                <TbCamera className="w-6 h-6" />
                Upload/update{" "}
                <input type="file" accept="image/*" className="hidden" />
              </label>
            </div>
            <div className="grid grid-cols-12 gap-4 mb-6">
              <div className="col-span-6 flex items-center gap-3">
                <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                  <RiUserLine className="w-6 h-6" />
                  Name:
                </div>
                <div className="text-base text-secondary">Zaki Aziz</div>
              </div>
              <div className="col-span-6 flex items-center gap-3">
                <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                  <img src="../images/Foray_images/cake.svg" alt="cake" />
                  DOB:
                </div>
                <div className="text-base text-secondary">04 Nov 2020</div>
              </div>{" "}
            </div>
            <div className="grid grid-cols-12 gap-4 mb-6">
              <div className="col-span-6 flex items-center gap-3">
                <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                  <img
                    className="p-1s"
                    src="../images/Foray_images/clock-full.svg"
                    alt="cake"
                  />{" "}
                  SA type:
                </div>
                <div className="text-base text-secondary">After</div>
              </div>
              <div className="col-span-6 flex items-center gap-3">
                <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                  <img src="../images/Foray_images/school-1.svg" alt="cake" />{" "}
                  School:{" "}
                </div>
                <div className="text-base text-secondary">Elementary</div>
              </div>{" "}
            </div>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-6 flex items-center gap-3">
                <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                  <AiOutlineTag className="w-6 h-6" />
                  Status:{" "}
                </div>
                <div className="text-base text-successAlert bg-[#18C55F26] border-successAlert rounded-3xl px-3 py-1">
                  Enrolled
                </div>
              </div>{" "}
            </div>
            <hr className="h-0.5 text-secondaryVariant2 my-9" />
            <h2 className="text-xl text-secondary font-semibold mb-8">
              Parents Information
            </h2>
            <div className="mb-6">
              <h3 className="text-secondary text-lg font-medium mb-6">
                Parents 1
              </h3>
              <div className="grid grid-cols-2 gap-6">
                <div className="text-base text-secondary flex items-center gap-3">
                  <RiUserLine className="w-6 h-6 text-secondaryVariant" />
                  <span>Arby Khan</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <IoMailOutline className="w-6 h-6 text-secondaryVariant" />
                  <span>arby.khan@gmail.com</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <FiPhone className="w-6 h-6 text-secondaryVariant" />
                  <span>123 456 789</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <img
                    src="../images/Foray_images/house-location.svg"
                    alt="cake"
                  />{" "}
                  <span>House #01 Street #01 Nawab town lahore</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <GrLocation className="w-6 h-6 text-secondaryVariant" />
                  <span>USA</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <CiGlobe className="w-6 h-6 text-secondaryVariant" />
                  <span>London</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <img src="../images/Foray_images/globe-06.svg" alt="globe" />{" "}
                  <span>54000</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <FiPhoneCall className="w-6 h-6 text-secondaryVariant" />
                  <span>No</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <PiUsers className="w-6 h-6 text-secondaryVariant" />
                  <span>Yes</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <TbBus className="w-6 h-6 text-secondaryVariant" />
                  <span>Yes</span>
                </div>
              </div>
              <hr className="h-0.5 text-secondaryVariant2 my-9" />
              <h3 className="text-secondary text-lg font-medium mb-6">
                Parents 2
              </h3>
              <div className="grid grid-cols-2 gap-6">
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <RiUserLine className="w-6 h-6 text-secondaryVariant" />
                    Name:
                  </div>
                  <span>Arby Khan</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <IoMailOutline className="w-6 h-6 text-secondaryVariant" />
                    Email:
                  </div>

                  <span>arby.khan@gmail.com</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <FiPhone className="w-6 h-6 text-secondaryVariant" />
                    Phone number:
                  </div>

                  <span>123 456 789</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <img
                      src="../images/Foray_images/house-location.svg"
                      alt="cake"
                    />{" "}
                    Address:
                  </div>
                  <span>House #01 Street #01 Nawab town lahore</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <GrLocation className="w-6 h-6 text-secondaryVariant" />
                    State:
                  </div>

                  <span>USA</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <CiGlobe className="w-6 h-6 text-secondaryVariant" />
                    City:
                  </div>

                  <span>London</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <img
                      src="../images/Foray_images/globe-06.svg"
                      alt="globe"
                    />{" "}
                    Zipcode:
                  </div>
                  <span>54000</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <FiPhoneCall className="w-6 h-6 text-secondaryVariant" />
                    Emergency Contact:
                  </div>

                  <span>No</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <PiUsers className="w-6 h-6 text-secondaryVariant" />
                    Lives with:
                  </div>

                  <span>Yes</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <TbBus className="w-6 h-6 text-secondaryVariant" />
                    Can Pick Up:
                  </div>

                  <span>Yes</span>
                </div>
              </div>
              <hr className="h-0.5 text-secondaryVariant2 my-9" />
              <h2 className="text-xl text-secondary font-semibold mb-8">
                Additional Contact
              </h2>
              <div className="mb-6">
                <h3 className="text-secondary text-lg font-medium mb-6">
                  Contact 1
                </h3>
                <div className="grid grid-cols-2 gap-6">
                  <div className="text-base text-secondary flex items-center gap-3">
                    <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                      <RiUserLine className="w-6 h-6 text-secondaryVariant" />
                      Name:
                    </div>
                    <span>Arby Khan</span>
                  </div>
                  <div className="text-base text-secondary flex items-center gap-3">
                    <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                      <IoMailOutline className="w-6 h-6 text-secondaryVariant" />
                      Email:
                    </div>

                    <span>arby.khan@gmail.com</span>
                  </div>
                  <div className="text-base text-secondary flex items-center gap-3">
                    <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                      <FiPhone className="w-6 h-6 text-secondaryVariant" />
                      Phone number:
                    </div>

                    <span>123 456 789</span>
                  </div>

                  <div className="text-base text-secondary flex items-center gap-3">
                    <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                      <FiPhoneCall className="w-6 h-6 text-secondaryVariant" />
                      Emergency Contact:
                    </div>

                    <span>No</span>
                  </div>
                  <div className="text-base text-secondary flex items-center gap-3">
                    <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                      <PiUsers className="w-6 h-6 text-secondaryVariant" />
                      Lives with:
                    </div>

                    <span>Yes</span>
                  </div>
                  <div className="text-base text-secondary flex items-center gap-3">
                    <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                      <TbBus className="w-6 h-6 text-secondaryVariant" />
                      Can Pick Up:
                    </div>

                    <span>Yes</span>
                  </div>
                </div>
              </div>
              <hr className="h-0.5 text-secondaryVariant2 my-9" />
              <h2 className="text-xl text-secondary font-semibold mb-8">
                Additional Contact
              </h2>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <img
                      src="../images/Foray_images/blackboard.svg"
                      alt="board"
                    />{" "}
                    Classroom:
                  </div>
                  <span>Butterfly2</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <RxCountdownTimer className="w-6 h-6 text-secondaryVariant" />
                    Timing Slot:
                  </div>
                  <span>Full Time</span>
                </div>
              </div>
              <hr className="h-0.5 text-secondaryVariant2 my-9" />
              <h2 className="text-xl text-secondary font-semibold mb-8">
                Critical Dates{" "}
              </h2>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <LuCalendarDays className="w-6 h-6 text-secondaryVariant" />
                    Joining Date:
                  </div>
                  <span>19 Aug 2024</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <PiCalendarDots className="w-6 h-6 text-secondaryVariant" />
                    Waitlisted Date:
                  </div>
                  <span>19 Aug 2024</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <LuCalendarX2 className="w-6 h-6 text-secondaryVariant" />
                    Leave Date:{" "}
                  </div>
                  <span>19 Aug 2024</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <LiaCarSolid className="w-6 h-6 text-secondaryVariant" />
                    Tour Date:
                  </div>
                  <span>19 Aug 2024</span>
                </div>
              </div>
              <hr className="h-0.5 text-secondaryVariant2 my-9" />
              <h2 className="text-xl text-secondary font-semibold mb-8">
                Transiton{" "}
              </h2>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <img
                      src="../images/Foray_images/blackboard.svg"
                      alt="board"
                    />{" "}
                    Classroom:
                  </div>
                  <span>Butterfly2</span>
                  <div className="text-base text-secondary font-semibold flex items-center gap-2">
                    <HiOutlineArrowRight className="w-5 h-5" />
                    Modified Class: Class name
                  </div>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <RxCountdownTimer className="w-6 h-6 text-secondaryVariant" />
                    Timing Slot:
                  </div>
                  <span>Full Time</span>
                  <div className="text-base text-secondary font-semibold flex items-center gap-2">
                    <HiOutlineArrowRight className="w-5 h-5" />
                    Modified Slot: Part Time{" "}
                  </div>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <img
                      src="../images/Foray_images/move-right.svg"
                      alt="board"
                    />{" "}
                    Transition date:
                  </div>
                  <span>04/12/2024</span>
                  <div className="text-base text-secondary font-semibold flex items-center gap-2">
                    <HiOutlineArrowRight className="w-5 h-5" />
                    Modified date: 04/12/2024{" "}
                  </div>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <PiCalendarDots className="w-6 h-6 text-secondaryVariant" />
                    Date Difference:
                  </div>
                  <span>10 days</span>
                </div>
              </div>
              <hr className="h-0.5 text-secondaryVariant2 my-9" />
              <h2 className="text-xl text-secondary font-semibold mb-8">
                Temporary absence (VAC/Wait hold){" "}
              </h2>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <LuCalendarDays className="w-6 h-6 text-secondaryVariant" />
                    Absence 1:
                  </div>
                  <span>19 Aug 2024 - 20 Aug 2024</span>
                </div>
              </div>
              <hr className="h-0.5 text-secondaryVariant2 my-9" />
              <h2 className="text-xl text-secondary font-semibold mb-8">
                Summer Program{" "}
              </h2>
              <div className="grid grid-cols-2 gap-6">
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <LuCalendarDays className="w-6 h-6 text-secondaryVariant" />
                    Summer Weeks:
                  </div>
                  <span>10:15am to 2:00pm</span>
                </div>
                <div className="text-base text-secondary flex items-center gap-3">
                  <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                    <PiSun className="w-6 h-6 text-secondaryVariant" />
                    Summer Activity:
                  </div>
                  <span>Math</span>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl">
            <h2 className="text-xl text-secondary font-semibold mb-8">
              Student Information
            </h2>
            <div className="grid grid-cols-1 gap-6">
              <div className="text-base text-secondary flex items-center gap-3">
                <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                  <LuCalendarDays className="w-6 h-6 text-secondaryVariant" />
                  Joining Date:
                </div>
                <span>10:15am to 2:00pm</span>
              </div>
              <div className="text-base text-secondary flex items-center gap-3">
                <div className="text-base text-secondaryVariant font-medium flex items-center gap-2">
                  <PiCalendarDots className="w-6 h-6 text-secondaryVariant" />
                  Transition Date:{" "}
                </div>
                <span>10:15am to 2:00pm</span>
              </div>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default ViewStudentProfile;
